/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

// import Header from './header';
import './layout.css';

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={data => (
            <>
                {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
                <div
                    style={{
                        margin: `0 auto`,
                        maxWidth: 960,
                        height: '100vh',
                        display: 'flex',
                        flexFlow: 'row wrap'
                    }}
                >
                    <main>{children}</main>
                    <footer style={{ textAlign: 'center', color: '#FFFFFF' }}>
                        © {new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.org">Gatsby</a>
                    </footer>
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
