import React from 'react';
import Link from 'gatsby-link';

import Layout from '../components/layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Página no encontrada" />
        <div className="main-container">
            <h1>Página no encontrada</h1>
            <p style={{ fontSize: '28px' }}>
                La magia no ha llegado a este rincón, aún, pero seguro pronto lo
                hará.
            </p>
            <p style={{ fontSize: '24px' }}>
                Mientras llega puedes visitar mas desde <Link to="/">aquí</Link>
            </p>
        </div>
    </Layout>
);

export default NotFoundPage;
